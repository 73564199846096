import React, { useEffect } from 'react';
import '../../assets/styles/joinFarmsell.css';
import SellerBuyer from '../../assets/images/seller-buyer.svg';
import Affiliate from '../../assets/images/affiliate.svg';
import { useNavigate } from 'react-router';
import navigateToTopPage from '../../shared/navigateTop'; // Assuming this file exists and exports navigateToTopPage function

const JoinFarmsell = () => {
  const navigate = useNavigate ();

  

  const registerWithFarmsll = (name) => {
    navigate(`/register/${name}`, { state: { role: name } });
  };
  
  

  // const joinFarmsellRedirect = () => {
  //   navigate ('/join-farmsell');
  // };

  useEffect(() => {
    navigateToTopPage();
  }, []);

  return (
    <div className="join-container">
      <div className="initalContainer">
        <h1>
          Join <span>Farmsell </span> Today – Unleash the
          <br />
          Potential of Your Agricultural <span>Transactions!</span>
        </h1>
        <div className="desc-text">
        Join our growing community of passionate and visionary people who are reshaping the landscape of agribusiness, fostering efficiency, reducing losses, and contributing to sustainable growth.
        </div>
      </div>
      <div className="cards-top-container" >
        <div className="card-item-container" onClick={() => registerWithFarmsll('buyer')}>
          <div className='rectangle'></div>
          {/* <img src={SellerBuyer} className="img-item-icon" alt='buyer icon' /> */}
          <h4 className="header-list">Buyer</h4>
          <div className="cont-desc">
            Are you interested in purchasing products, catering to industries be it hotels, restaurants, agro-industries, supermarkets, or groceries?
          </div>
          <button className="join-btn" onClick={() => registerWithFarmsll('buyer')}>
            Join Now
          </button>
        </div>
        <div className="card-item-container" onClick={() => registerWithFarmsll('seller')}>
        <div className='rectangle'></div>
          {/* <img src={SellerBuyer} className="img-item-icon" alt='seller icon'/> */}
          <h4 className="header-list">Seller</h4>
          <div className="cont-desc">
            Become a Farmsell supplier providing to the world
          </div>
          <button className="join-btn" onClick={() => registerWithFarmsll('seller')}>Join Now</button>
        </div>
        <div className="card-item-container" onClick={() => registerWithFarmsll('partner')}>
        <div className='rectangle'></div>
          {/* <img src={Affiliate} className="img-item-icon" alt='partner icon' /> */}
          <h4 className="header-list">Partner</h4>
          <div className="cont-desc">
            Are you interested in partnering with Farmsell, whether you're an individual, freelancer, organization, influencer?
          </div>
          <button className="join-btn" onClick={() => registerWithFarmsll('partner')}>Join Now</button>
        </div>
      </div>
    </div>
  );
};

export default JoinFarmsell;