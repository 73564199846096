import { useParams } from 'react-router-dom';
import '../../assets/styles/RegPage.css';
import Welcomex from '../Welcomex/index';
import Welcomexx from '../Welcomexx/index';
function WelcomeComponent() {
    const { name } = useParams();

    return (
        <div>
            <Welcomex />
            <div className="hey" style={{ marginTop: '-10%',marginBottom: '2%' }}>
                Hey {' '}
                <span className="linear-color">{name}!</span> {' '}
                Ready for an <br />
                <div>amazing Farmsell experience? </div>

                <span
                    className="linear-color"
                    style={{ display: 'block', marginLeft: '0%' }}
                >
                    Let's Go!
                </span>
            </div>
            <Welcomexx />
        </div>
    );
}

export default WelcomeComponent;
