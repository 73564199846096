// import React, { useEffect, useState } from 'react';
// import Header from '../Header/index';
// import { CSSTransition } from 'react-transition-group';
// import { useSpring, animated } from 'react-spring';
// import '../../assets/styles/pageNotfound.css';
// import image from '../../assets/images/pagenotfound.png'; 

// const PageNotFound = () => {
//   const [loaded, setLoaded] = useState(false);

//   useEffect(() => {
//     setLoaded(true);
//   }, []);

//   return (
//     <CSSTransition in={true} timeout={500} classNames="fade">
//       <div className={`page-not-found ${loaded ? 'loaded' : ''}`}>
//         <Header />
//         <div className="content">
//           <div className="left">
//             <img src={image} alt="Error" />
//           </div>
//           <div className="right">
//             <h1>Oops... page not found,</h1>
//             <p>Sorry! The page you're looking for doesn't exist. Please use the search box for help or go to the homepage.</p>
//             <div className="buttons">
//               <button onClick={() => window.history.back()}>Go Back</button>
//               <button onClick={() => window.location.href = '/'}>Return to Homepage</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </CSSTransition>
//   );
// };

// export default PageNotFound;


import React from 'react';
import {useNavigate} from 'react-router-dom';
import '../../assets/styles/pageNotfound.css';
import notImage from '../../assets/images/pagenotfound.png'; 
import Header from '../Header/index';
import Footer from '../Footer';
const PageNotFound = () => {
  const navigate = useNavigate ();

  return (
    <div>
      <Header />
      <div className="notfound">
        <img src={notImage} alt="Not Found" className="image-cat" />
        <div>
          <h1 className="not-oops">oops... page not found</h1>
          <p className="not-sorry">
            Sorry! Page you're looking for doesn't exist. Please use the search box for help or go to the homepage.
          </p>
          <div className="not-btn">
            <button onClick={() => navigate (-1)}>Go back</button>
            <button onClick={() => navigate (`/`)}>Go to homepage</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PageNotFound;
