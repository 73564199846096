import Google from '../../assets/images/Google Play.svg';
import Linkedin from '../../assets/images/Linkedin.png';
import Tube from '../../assets/images/Youtube.png';
import FB from '../../assets/images/facebook.png';
import Twitter from '../../assets/images/Twitter X.png';
import Arrow from '../../assets/images/Arrow.png';
const SecondFooter = () => {
  const currentYear = new Date().getFullYear();
  const twitterProfileUrl = 'https://twitter.com/FarmsellApp'
  const fbProfileUrl = 'https://www.facebook.com/farmsellapp'
  const ytProfileUrl = 'https://www.youtube.com/channel/UCMpw1sXyo9NGFgGuFHYkz7w'
  const linkProfileUrl = 'https://www.linkedin.com/company/farmsell33'
  return (
    <div className="second-container">

      <div className='scroll-up mobile-none'>
        <img src={Arrow} alt="Farmsell IOS Icon" />
      </div>

      <div className='copy-rights'>
        Copyright © {currentYear} FARMSELL. All rights reserved.
      </div>


    </div>
  );
};

export default SecondFooter;
