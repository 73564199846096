const constants = {
  refresh_token: '1000.b7c40740abd5ff6453d1c187b9aec00f.4709dd1dfd3e215eb22e25dbe95e0230',
  client_secret: '7055aa2957d3e1cf7d864f05399063e481b7a10fc9',
  client_id: '1000.O5AAL4XV723SRHN03FM2GRNY3YUPZG',
  listCode: '3z5001b7bedef549063d1f071828509899b461afc9091d55280e15c7342adc5a79',
  localUrl: 'http://127.0.0.1:8000/api/user/',
  productionUrl: 'https://farmsell.org/API/public/api/user/',


  url: 'https://farmsell.org/API/public/api/user/',
  adminUrl: 'https://farmsell.org/API/public/api/admin',
  domain_name: 'https://farmsell.org/API/public',
  urlSeller: 'https://farmsell.org/API/public/api/seller/',


// url: 'http://10.0.2.2:8000/api/user/',
// adminUrl: 'http://10.0.2.2:8000/api/admin',
// domain_name: 'http://10.0.2.2:8000',
// urlSeller: 'http://10.0.2.2:8000/api/seller/',
//  //
};

export default constants;
