import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/styles/WelcomeToFarmsell.css';
import OIDImage5 from '../../assets/images/Hero/red.png';
import ButtonArrow from '../../assets/images/welcomebuttonarrow.svg';
import { useNavigate } from 'react-router';

const WelcomeToFarmsell = () => {
  const navigate = useNavigate();

  const aboutFarmsellRedirect = () => {
    navigate('/about-us');
  };

  return (
    <div className="welcome-container">
      <div className="text-content">
        <h1>
        Redefining Agricultural
          <span className="gradient-text"> Trade</span>
        </h1>
        <p>
        Transforming agriculture, one connection at a time. Farmsell is your 
        trusted marketplace where farmers, businesses, and innovators come together to trade, 
        grow, and thrive. Join us in shaping the future of agriculture!
        </p>
        <button className="learn-more-btn" onClick={aboutFarmsellRedirect}>
          Read About Us
          <span className='ms-4'>
          </span>
        </button>
      </div>
      <div className="image-container">
        <img src={OIDImage5} alt="Image 5" className="responsive-image" />
      </div>
    </div>
  );
};

export default WelcomeToFarmsell;
