import '../../assets/styles/RegPage.css';
import Shadow from '../../assets/images/Shadow.png';
import imgFooter from '../../assets/images/Group 1272628218.svg';

const Welcomexx = () => {
  return (
    <div style={{ marginLeft: '10%', marginTop: '0.5%' }}>
      <div className='custom-flexxs-row scelres'>
        <ul style={{ fontSize: 25, listStyleType: 'disc' }}>
          <li>
            {' '}
            <span style={{ fontWeight: 'bold' }}>Check Email Inbox:</span>
            {' '}
            Go to your registered email address
          </li>
          <li>
            {' '}
            <span style={{ fontWeight: 'bold' }}>Open Our Email:</span>
            {' '}
            With Subject "Welcome to Farmsell: Together we redefined Agro trading."
          </li>
          <li>
            {' '}
            <span style={{ fontWeight: 'bold' }}>Check Junk/Spam: </span>
            Just in case, check junk/spam folders of your email address
          </li>
          <li>
            <span style={{ fontWeight: 'bold' }}>
              Read and Act:
            </span>
            {' '}
            Follow the instructions in the email from Farmsell.
          </li>
          <li>
            {' '}
            <span style={{ fontWeight: 'bold' }}>Confirm Receipt: </span>
            Shoot us a quick reply to confirm you're all set!
          </li>
        </ul>

        <div style={{ marginLeft: '2%', marginTop: -245 }}>
          <img src={imgFooter} style={{ width: '105%', height: '123%', marginLeft: '-17%' }} className='berdsec' />
          <img src={Shadow} style={{ width: '80%', display: 'none', height: '80%' }} />
        </div>
      </div>
    </div>
  );
}

export default Welcomexx;
