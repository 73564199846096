import React, { useState, useEffect } from 'react';
import '../../assets/styles/tailoredbenefits.css';
import ArrowUp from '../../assets/images/arrowup.svg';
import ArrowDown from '../../assets/images/arrowdown.svg';
import SmallHolder from '../../assets/images/tailored/smallholdFarmer.png';
import AgroBased from '../../assets/images/tailored/industries.png';
import HotelandRestorant from '../../assets/images/tailored/hotelRest.png';
import Supermarkets from '../../assets/images/tailored/super.png';
import AgroInputs from '../../assets/images/tailored/agroinput.png';

const TailoredBenefits = () => {
  const [openSection, setOpenSection] = useState(0);

  // Function to toggle sections
  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  // Auto-switching logic
  useEffect(() => {
    const interval = setInterval(() => {
      setOpenSection((prevSection) =>
        prevSection < sections.length - 1 ? prevSection + 1 : 0
      );
    }, 10000); // Auto-switch every 10 seconds
    return () => clearInterval(interval);
  }, []);

  const sections = [
    {
      title: 'Smallholder Farmers',
      content:
        'Empowering Your Harvest, Empowering Your Life. Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs.',
      img: SmallHolder,
    },
    {
      title: 'Agro-Input Enterprises',
      content:
        'Connecting Quality Inputs to the Fields that Need Them. Agro-input enterprises, Farmsell is your direct gateway to farmers seeking top-notch tools and inputs. Simplify your supply chain and witness your products seamlessly connecting with the farms that can benefit the most.',
      img: AgroInputs,
    },
    {
      title: 'Hotels and Restaurant Chains',
      content:
        'Feeding Success: Direct Access to Quality Farm Produce. The hospitality industry, Farmsell is your key to sourcing the freshest and highest-quality farm produce directly from farmers. Elevate your offerings and contribute to the success of your institution with Farmsell.',
      img: HotelandRestorant,
    },
    {
      title: 'Supermarkets and Groceries',
      content:
        'Elevate Your Shelves with Freshness. In the retail industry, Farmsell is your gateway to securing a constant supply of the freshest and highest-quality farm produce directly from local farmers. Enhance your product offerings and contribute to the success of your store.',
      img: Supermarkets,
    },
    {
      title: 'Agro-Based Industries',
      content:
        'Efficiency with Direct Access to Quality Raw Materials. The Agro-Based industries, your efficiency journey begins with Farmsell. Gain direct access to high-quality raw materials sourced directly from farmers.',
      img: AgroBased,
    },
  ];

  return (
    <div className="tailored-benefits">
      <div className="header">
        <h1>
          Tailored <span className="colored">Benefits</span> for Every{' '}
          <span className="colored">Stakeholder</span>
        </h1>
        <div className="desc-text">
          Farmsell brings tailored solutions, empowering every stakeholder in
          agriculture with efficiency and direct benefits.
        </div>
      </div>

      <div className="content">
        {/* Accordion List */}
        <div>
          {sections.map((section, index) => (
            <div
              key={index}
              className={`element ${openSection === index ? 'open' : ''}`}
            >
              <div
                className="accordion-toggle"
                onClick={() => toggleSection(index)}
                aria-expanded={openSection === index}
              >
                <div className="progress-bar" aria-hidden="true"></div>
                {section.title}
                {openSection === index ? (
                  <img className="icon" src={ArrowUp} alt="arrow up" />
                ) : (
                  <img className="icon" src={ArrowDown} alt="arrow down" />
                )}
              </div>
              <div
                className={`accordion-content ${
                  openSection === index ? 'show' : ''
                }`}
              >
                <div>
                  <p>{section.content}</p>
                  <div className="phone-image">
                    <img
                      src={section.img}
                      alt={`Visual aid for ${section.title}`}
                      className={`accordion-image ${
                        openSection === index ? 'show' : 'hide'
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Desktop Image */}
        <div className="destop-image">
          <img
            src={sections[openSection].img}
            alt={`Visual aid for ${sections[openSection].title}`}
            className="fade-in"
          />
        </div>
      </div>
    </div>
  );
};

export default TailoredBenefits;
