import React, { useState } from 'react';
import '../../../assets/styles/about-us/corevalues.css';


const CoreValues = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index);
  };

  const menuItems = [
    {
      title: 'Cultivating Sustainability',
      desc: "Sustainability is not an option; it's our responsibility. Farmsell is dedicated to promoting eco-friendly practices, reducing our environmental footprint, and supporting suppliers committed to sustainable agriculture. Join us in cultivating a greener, more sustainable future for the agricultural sector.",
    },
    {
      title: 'Harvesting Integrity',
      desc: "At Farmsell, integrity is our cornerstone. We cultivate trust through transparent and ethical practices, ensuring that every interaction, from seed to sale, reflects our unwavering commitment to honesty and fairness. We believe that a foundation built on integrity is the key to nurturing enduring relationships within the agricultural community.",
    },
    {
      title: 'Growth Through Innovation',
      desc: "Innovation is our lifeblood. We thrive on pushing boundaries, constantly evolving to bring cutting-edge solutions to the forefront of agricultural transactions. Farmsell is not just a marketplace; it's a dynamic ecosystem where technological advancements meet the soil, fostering growth, and prosperity for all stakeholders in the agricultural landscape.",
    },
    {
      title: 'Seamless Connectivity',
      desc: "In the vast fields of B2B transactions, Farmsell stands as the bridge that seamlessly connects buyers with sellers. Our smart-sourcing technology ensures a frictionless experience, simplifying bulk transactions and fostering a community were agricultural connections flourish effortlessly.",
    },
    {
      title: 'Rooted in Empowerment:',
      desc: "Empowerment is the driving force behind Farmsell. We empower smallholder farmers, agri-input enterprises, and institutions to take control of their agricultural journey. Farmsell is your partner in empowerment, fostering a platform where success grows from the roots up.",
    }
  ];


  return (
    <div className='corevalues'>
      <div className='header'>
        <h2>Core <span className='colored'>Values</span>.<br />
          <span className='colored'>Cultivating</span> Excellence in Agriculture</h2>

        <div className='desc-text'>Farmsell's core values are the seeds that grow into a flourishing agricultural ecosystem. We embrace sustainability, integrity, innovation, connectivity, and empowerment abbreviated as <b>“SIICE”</b> pronounced as <b>“SEES”.</b>
        </div>
      </div>

      <div className="menu-details-container">
        <div className="menu-container">
          <ul>
            {menuItems.map((item, index) => (
              <li
                key={index}
                className={index === selectedMenuItem ? 'active' : ''}
                onClick={() => handleMenuItemClick(index)}
              >
                <div className='ca-active'></div>{item.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="details-container for-web">
          <h2>{menuItems[selectedMenuItem].title}</h2>
          <p>{menuItems[selectedMenuItem].desc}</p>
        </div>
        <div className='for-mobile'>
          {menuItems.map((item, index) => (
            <div className="details-container">
              <h2>{item.title}</h2>
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
