import React from 'react';


const BlogPage = () => {
  return (
    <div>
     
     
      
    </div>
  );
};

export default BlogPage;
