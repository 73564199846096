import React, { useState } from "react";
import "../../assets/styles/testimonialPage.css"; 
import Alice from "../../assets/images/Testimonies/Alice.jpeg";
import Bob from "../../assets/images/Testimonies/Bob.jpeg";
import Char from "../../assets/images/Testimonies/char.jpg";
import Daniel from "../../assets/images/Testimonies/daniel.jpg";
import Grace from "../../assets/images/Testimonies/Alice.jpeg";
import AliceK from "../../assets/images/Testimonies/Alice.jpeg";
import John from "../../assets/images/Testimonies/Alice.jpeg";
import Okell from "../../assets/images/Testimonies/Alice.jpeg";

const testimonials = [
  {
    name: "Alice",
    role: "A tomato farmer in Uganda",
    image: Alice,
    message: "Farmsell has been a game-changer for my farming business. I used to struggle to find buyers for my products, but now I can easily list them and get matched with buyers who are willing to pay fair prices. It's increased my income and improved my livelihood."
  },
  {
    name: "Bob",
    role: "A food factory manager in Kenya",
    image: Bob,
    message: "Farmsell has been a great partner for my food factory. I used to face issues with suppliers, but now I can find quality and affordable agricultural products from verified sellers, reducing my costs and improving productivity."
  },
  {
    name: "Charles",
    role: "An impact investor in South Africa",
    image: Char,
    message: "Farmsell has been a valuable resource for my investment portfolio. I can now discover and support sustainable agricultural projects in Africa, diversifying my investments and improving returns."
  },
  {
    name: "John Mbeki",
    role: "Smallholder Farmer",
    image: John,
    message: "Farmsell has transformed the way I sell my produce. I connect directly with buyers, ensuring fair prices and reducing post-harvest losses. It's a game-changer for small farmers like me!"
  },
  {
    name: "Alice Kariuki",
    role: "Agri-Input Business Owner",
    image: AliceK,
    message: "Managing my agri-input business has never been easier. Farmsell's smart-matching capabilities connect me with farmers in need of quality inputs, making transactions efficient and profitable."
  },
  {
    name: "Grace Mwangi",
    role: "Procurement Manager, Coralloid Agro-Industry",
    image: Grace,
    message: "Farmsell has significantly improved our supply chain efficiency. With its focus on agricultural transactions, we can source raw materials more reliably and cost-effectively."
  },
  {
    name: "Daniel K. Nsubuga",
    role: "Director, Green Solutions Limited",
    image: Daniel,
    message: "Farmsell has been instrumental in expanding my customer base. The platform's user-friendly interface allows me to connect with buyers, showcase my products, and grow my business."
  },
  {
    name: "Samuel Okello",
    role: "Hotel Owner",
    image: Okell,
    message: "Farmsell has become invaluable for our hotel's sourcing needs. I can easily find quality produce, ensuring we maintain high standards in our culinary offerings."
  },
  {
    name: "Grace Mwangi",
    role: "Procurement Manager, Coralloid Agro-Industry",
    image: Grace,
    message: "Farmsell has made sourcing agricultural products for my business so much easier. The platform's direct links to farmers and suppliers save us time and money."
  },
  {
    name: "Alice Kariuki",
    role: "Agri-Input Business Owner",
    image: AliceK,
    message: "With Farmsell, I’ve been able to streamline my B2B transactions. The platform connects me with farmers looking for quality agri-inputs, making the process smooth and profitable."
  }
];

const TestimonialPage = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="testimonialPage">
      <div className="testimonialHeader">
        <h1>
          <span>Revolutionizing </span> Agriculture with
          <br />
          Farmsell. What our <span>customers</span> Say
        </h1>
        <div className="desc-text">
          Farmsell is changing agriculture, making every transaction smooth, efficient, and more profitable. Join the revolution and be part of the change!
        </div>
      </div>

      <div 
        className={`testimonialSlider ${isHovered ? 'paused' : ''}`}  // Add "paused" class when hovering
      >
        <div className="sliderTrack">
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <div 
              key={index} 
              className="testimonialItem"
              onMouseEnter={() => setIsHovered(true)}  // Set hover state to true
              onMouseLeave={() => setIsHovered(false)}  // Set hover state to false
            >
              <div className="top-item-testimony">
                <img src={testimonial.image} className="testimonialImage" alt={testimonial.name} />
                <div className="name-title">
                  <h5>{testimonial.name}</h5>
                  <p>{testimonial.role}</p>
                </div>
              </div>
              <div className="bottom-item-testimony">
                <blockquote className="testimonialMessage">
                  {`“${testimonial.message}”`}
                </blockquote>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialPage;
