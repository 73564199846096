import React from 'react'
import '../../assets/styles/mobileHowItWorks.css';

const MobileContent = ({ imageSrc, title, sub_titles, description }) => {
  return (
    <div>
          <div className="mobile-container">
        <div className="imagemobile-container">
          <img className='mobile-images' src={imageSrc} alt="Step" />
        </div>
        <div className="textMobile-container">
        
        <h1 className='stepsMobile-title'>{title}</h1>
       
          
          <h1 className='stepsMobile-sub-t'>{sub_titles}</h1>
          <p className='stepsMobile-p'>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default MobileContent