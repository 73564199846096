import React from "react";
import "../../assets/styles/sellerAgreement.css";
import Header from "../Header";
import BannerIMG from "../../assets/images/sellerAgreement.svg";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const SellerAgreement = () => {
    return (
        <div>
            <Header />
            <div className="agreement-banner">
                <div className="sect-title">
                    <h1>Empower Your Harvest: Sell Smarter with Farmsell Marketplace</h1>
                    <p>
                    Transform Your Agricultural Business with Our Innovative Platform.
                    </p>
                    <Link to="/join-farmsell" className="link-button">Start Selling Today</Link>
                </div>
                <div className="sect-image">
                    <img src={BannerIMG} alt="Aggrment banner" />
                </div>
            </div>
            <div className="agreement-content">
                <div className="an-element" >
                    <h1>1. Introduction</h1>
                    <p>
                        This Seller Agreement ("Agreement") outlines the terms and conditions under which you ("Seller") may sell
                        products on the Farmsell Marketplace ("Marketplace"). By registering as a Seller on the Marketplace,
                        you agree to be bound by this Agreement.
                    </p>
                </div>


                <div className="an-element" >
                    <h1>2. Definitions </h1>
                    <ul>
                        <li><b>Farmsell Platform:</b> Refers to the online marketplace provided by Farmsell for agricultural products and services.</li>
                        <li><b>Seller:</b> Any individual or entity registered as a seller on the Farmsell platform.</li>
                        <li><b>Buyer:</b> Any individual or entity registered as a buyer on the Farmsell platform.</li>
                        <li><b>Product(s):</b> Agricultural goods, products, and services offered for sale by the Seller on the Farmsell platform.</li>
                    </ul>
                </div>


                <div className="an-element" >
                    <h1>3. Eligibility</h1>
                    <p><b>To become a Seller on the Marketplace, you must:</b></p>
                    <ul>
                        <li>Be at least 18 years old.</li>
                        <li>Have a valid National ID or Password</li>
                        <li>Have a valid business registration and license.</li>
                        <li>Provide accurate and complete registration information.</li>
                        <li>Ensure that your business is legally authorized to sell products.</li>
                    </ul>
                </div>


                <div className="an-element" >
                    <h1>4. Seller Obligations</h1>
                    <ul>
                        <li>Provide accurate and complete information about the Products listed for sale, including descriptions, prices, and availability.</li>
                        <li>Maintain high-quality standards for all Products listed on the Farmsell platform.</li>
                        <li>Fulfill orders promptly and efficiently in accordance with the terms specified in this Agreement.</li>
                        <li>Comply with all applicable laws and regulations governing the sale and distribution of agricultural products.</li>
                        <li>Respond promptly to inquiries and communication from Buyers and Farmsell regarding Products and orders.</li>
                        <li>Ensure the safety, quality, and integrity of all Products sold through the platform.</li>

                    </ul>
                </div>


                <div className="an-element" >
                    <h1>5. Farmsell's Obligations</h1>
                    <ul>
                        <li>Provide access to the Farmsell platform for the listing and sale of Products by the Seller.</li>
                        <li>Facilitate transactions between Sellers and Buyers on the Farmsell platform.</li>
                        <li>Provide customer support to assist Sellers and Buyers with inquiries, issues, and disputes.</li>
                        <li>Process payments for Products sold through the Farmsell platform and remit payments to Sellers in a timely manner, minus any applicable Commissions.</li>
                        <li>Implement security measures to protect the integrity and confidentiality of Seller and Buyer information.</li>

                    </ul>
                </div>


                <div className="an-element" >
                    <h1>6. Product Requiremnts</h1>
                    <ul>
                        <li>Sellers must ensure that their products comply with all applicable laws and regulations.</li>
                        <li>Products must be accurately described and pictured.</li>
                        <li>Sellers must ensure that their products are packaged and shipped in a manner that prevents damage.</li>
                        <li>Sellers must ensure that their products are not counterfeit or infringe on the intellectual property rights of others.</li>
                    </ul>
                </div>


                <div className="an-element" >
                    <h1>7. Pricing and Payment</h1>
                    <ul>
                        <li>The Seller shall set the prices for Products listed on the platform while ensure that the pricing is fair and competitive within the market.</li>
                        <li>Payments for products are handled directly between the seller and buyer, as agreed upon in their transaction.</li>
                        <li>Sellers are responsible for ensuring that payment arrangements are clearly communicated and agreed upon with buyers prior to completing a transaction.</li>
                    </ul>
                </div>


                <div className="an-element" >
                    <h1>8. Shipping and Delivery</h1>
                    <ul>
                        <li>Sellers are responsible for negotiating shipping and delivery terms directly with buyers.</li>
                        <li>Sellers must provide accurate shipping details and estimated delivery times to buyers.</li>
                        <li>Sellers must ensure that products are shipped within the timeframe specified in the product listing or as agreed upon with the buyer.</li>
                        <li>Sellers must ensure that products are delivered to the buyer's specified address as communicated during the transaction process.</li>

                    </ul>
                </div>


                <div className="an-element" >
                    <h1>9. Intellectual Property</h1>
                    <ul>
                        <li>Seller retains all rights, title, and interest in and to any intellectual property rights associated with the products listed on the Platform.</li>
                        <li>Seller grants Farmsell a non-exclusive, worldwide, royalty-free license to use, display, and reproduce Seller's intellectual property solely for the purpose of operating the Platform.</li>
                        <li>Sellers must ensure that their products do not infringe on the intellectual property rights of others. Any products that infringe on the intellectual property rights of others shall be removed from the platform.</li>

                    </ul>
                </div>

                <div className="an-element" >
                    <h1>10. Account Termination</h1>
                    <ul>
                        <li>This Agreement begins on the Effective Date (the account creation date) and continues until terminated as outlined herein. Either Party may terminate this Agreement upon [14] days' written notice for any reason.</li>
                        <li>Farmsell reserves the right to suspend or terminate the Seller's access to the Farmsell platform without notice in case of any breach of this Agreement or violation of Farmsell's policies or Governing Laws.</li>
                        <li>Should the Seller choose to terminate, they must first address any outstanding issues with buyers before closing their account. This includes ensuring timely delivery of all products and issuing refunds as necessary.</li>

                    </ul>
                </div>


                <div className="an-element" >
                    <h1>11. Dispute Resolution</h1>
                    <ul>
                        <li>Sellers must respond to buyer complaints and resolve disputes in a timely and professional manner.</li>
                        <li>Farmsell Marketplace may assist in resolving disputes between buyers and sellers.</li>
                        <li>Sellers must cooperate with Farmsell Marketplace in resolving disputes.</li>

                    </ul>
                </div>


                <div className="an-element" >
                    <h1>12. Governing Law</h1>
                    <ul>
                        <li>This Agreement shall be governed by and construed in accordance with the laws of Uganda.</li>
                        <li>Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of Uganda.</li>

                    </ul>
                </div>


                <div className="an-element" >
                    <h1>13. Miscellaneous</h1>
                    <ul>
                        <li>This Agreement constitutes the entire agreement between the Parties regarding the subject matter hereof and supersedes all prior agreements and understandings, whether written or oral.</li>
                        <li>This Agreement may not be amended or modified except in writing signed by both parties.</li>
                        <li>The headings in this Agreement are for convenience only and shall not affect the interpretation of any provision.</li>
                        <li>Sellers must indemnify and hold harmless Farmsell and its affiliates from any claims, damages, or expenses arising out of or related to their products or conduct on the Marketplace.</li>
                        <li>The Marketplace is provided on an "as is" and "as available" basis, without warranties of any kind, express or implied.</li>
                        <li>Farmsell disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</li>

                    </ul>
                </div>

                <div className="an-element" >
                    <h1>14. Acceptance</h1>
                    <ul>
                        <li>By registering as a Seller on the Farmsell platform, the Seller acknowledges that they have read, understood, and agreed to be bound by the terms and conditions of this Agreement.</li>

                    </ul>
                </div>
            </div>
            <Footer />
        </div>


    );
};

export default SellerAgreement;