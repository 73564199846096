import React from 'react'
import MobileContent from './MobileContent'
import step1 from '../../assets/images/HowItWorks/step1.svg'
import step2 from '../../assets/images/HowItWorks/step2.png'
import step3 from '../../assets/images/HowItWorks/step3.svg'
import step4 from '../../assets/images/HowItWorks/step4.svg'
import step5 from '../../assets/images/HowItWorks/step5.svg'
import step6 from '../../assets/images/HowItWorks/step6.svg'
import step7 from '../../assets/images/HowItWorks/step7.jpg'
import step8 from '../../assets/images/HowItWorks/step8.svg'


const MobileSteps = () => {
    return (
        <div>
            <MobileContent
                imageSrc={step1}
                title="Step 1"
                sub_titles="Registration"
                description="The sellers and buyers register on the Farmsell platform and create their profiles. 
              They provide information such as their location, contact details, product preferences, and payment methods."


            />

            <MobileContent
                imageSrc={step2}
                title="Step 2"
                sub_titles="Product Listing"
                description="The sellers list their products on the platform and provide details such as the quantity, quality, price, 
                and availability of the products. They can also upload photos or videos of the products to showcase their features."
            />

            <MobileContent
                imageSrc={step3}
                title="Step 3"
                sub_titles="Matching"
                description="The Farmsell platform uses AI to match the sellers and buyers based on their profiles and preferences. The platform notifies the sellers and buyers
                 of the potential matches and allows them to view each other's profiles and products."
            />

            <MobileContent
                imageSrc={step4}
                title="Step 4"
                sub_titles="Negotiation"
                description="The sellers and buyers communicate with each other through the platform and negotiate the terms and conditions of the transaction. They can 
                also request or provide additional information or verification of the products."
            />

            <MobileContent
                imageSrc={step5}
                title="Step 5"
                sub_titles="Confirmation"
                description="The sellers and buyers confirm the transaction and agree on the payment and delivery methods. The Farmsell platform 
                generates a contract and an invoice for the transaction and sends them to both parties"
            />

            <MobileContent
                imageSrc={step6}
                title="Step 6"
                sub_titles="Payment"
                description="The buyers pay for the products through the platform using their preferred payment method. 
                The Farmsell platform holds the payment in escrow until the delivery is confirmed"
            />


            <MobileContent
                imageSrc={step7}
                title="Step 7"
                sub_titles="Delivery"
                description="The sellers deliver the products to the buyers using their preferred delivery method. 
                The Farmsell platform tracks the delivery status and notifies both parties of the progress."
            />

            <MobileContent
                imageSrc={step8}
                title="Step 8"
                sub_titles="Completion"
                description="The buyers receive the products and confirm the delivery through the platform. The Farmsell platform releases the payment to the sellers and closes the transaction. 
                The sellers and buyers rate and review each other and the products on the platform."
            />
        </div>
    )
}

export default MobileSteps