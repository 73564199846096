import React from "react";
import "../../assets/styles/contact-us/contact-us.css";
import ContactBanner from "./banner";
import GetinTouch from "./getInTouch";
import ContactPopup from "./ContactPopup";
import Header from "../Header";
import Footer from "../Footer";

const ContactUs = () =>{
    return(
        <div>
            <Header />
            <ContactBanner />
            <GetinTouch />
            <ContactPopup />
            <Footer />
        </div>
    )
}

export default ContactUs;