import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import '../../assets/styles/contact-us/ContactPopup.css';

function ContactPopup({ isOpen, onClose }) {
  useEffect(() => {
    // Add or remove the 'popup-open' class from the body based on the isOpen state
    if (isOpen) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('popup-open');
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <img src="https://img.freepik.com/premium-vector/contact-us-sign-contact-us-speech-bubble-popup-contact-us-label-tag-support-line-concept_849264-238.jpg?w=740" alt="Your Image" className="popup-image" />
        <h2 className='clkrtg'>Hello there!</h2>
        <p className='mnlpo'>You have successfully submitted the form!</p>
      </div>
    </div>
  );
}

export default ContactPopup;
