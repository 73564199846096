import React from 'react'
import '../../assets/styles/TradeShows.css'
import ButtonArrow from '../../assets/images/welcomebuttonarrow.svg';
import TradeImage from '../../assets/images/tradeShows.svg'
export default function TradeShows() {
  return (
    <div className='t-c'id="trade-shows">


    <div className='t-wrapper'>

        <div className='t-header'>
           
           <h2 className='heading'>
                <span className='bg-text'>Trade Shows</span><br/>
               <span style={{color:"#274C5B"}}>  Uniting Agriculture, Showcasing <br/> Excellence</span>
                </h2>
            
           
        </div>
        <div className='t-w'>
            
            <div className='t-text'>
               <div className='t-para'> <p className='t-p'>
                Immerse yourself in the power of face-to-face connections at Farmsell's tradeshows. These impactful events, held bi-annually or quarterly, bring together the entire agricultural ecosystem under one roof. From smallholder farmers to agro industries, explore cutting-edge products, forge new partnerships, and witness the best of what agriculture has to offer. Join us in celebrating the diversity and excellence of our community.
                </p></div>
               <div className='t-btnW'> <button
                className='t-btn'

    >
     Attend the Next Tradeshow
      <span className=''>
        <img src={ButtonArrow} alt='buttonarrow' />
      </span>
    </button></div>
            </div>
            <div className='t-img'>
                <img className='t-images' src={TradeImage} alt='forumImage'/>
            </div>

        </div>
    </div>
</div>
  )
}
