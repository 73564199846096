import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink as ScrollLink } from 'react-router-hash-link'; // Import HashLink
import { useLocation } from 'react-router-dom';

const LinkList = () => {
  const handleContactUsClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const location = useLocation();

  useEffect(() => {
    // Scroll to the correct section if the hash exists in the URL
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        const duration = 800; // Duration of scrolling animation
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offset = 100; 
        const targetScroll = elementPosition - offset;
        window.scrollTo({
          top: targetScroll,
          behavior: "smooth",
          duration: duration // Setting the duration for smooth scrolling
        });
      }
    }
  }, [location.hash]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="linkedListContainer">
      <div className="footerLinks">
        <ul>
          <li style={{color: '#F7B618', fontWeight: '600', fontSize: 20}}>
            About us
          </li>
          <li><Link to="/about-us">Founding Story</Link></li>
          <li><Link to="/about-us">Core Principles</Link></li>
          <li><Link to="/about-us">Vision & Mission</Link></li>
          <li style={{display:'none'}}><Link to="/about-us">Core Values</Link></li>
          <li><Link to="/about-us">Our Approach</Link></li>
          <li><Link to="/about-us">Partnerships</Link></li>
        </ul>

        <ul>
          <li style={{color: '#F7B618', fontWeight: '600', fontSize: 20}}>
            Useful Links
          </li>
          <li style={{display:'none'}}><Link to="/blog-page"  onClick={handleContactUsClick}>Blog</Link></li>
          <li><Link to="/contact-us" onClick={handleContactUsClick}>Contact</Link></li>
          <li><Link to="/how-we-work">How We Work</Link></li>
          <li style={{display:'none'}}><a href="/core-values.html">Help center</a></li>
          <li><Link to="#" onClick={() => openInNewTab("/terms")}>Terms of Use</Link></li>
          <li><Link to="#" onClick={() => openInNewTab("/privacy")}>Privacy Policy</Link></li>
          {/* <li><Link to="#" onClick={() => openInNewTab("/seller-agreements")}>Sellers’ Agreements</Link></li> */}
        </ul>

        <ul>
          <li style={{color: '#F7B618', fontWeight: '600', fontSize: 20}}><Link to="/community" onClick={handleContactUsClick} style={{color: 'rgb(247, 182, 24)',
            fontWeight: '600',
            fontSize: '20px'}}>
            Community  </Link>
          </li>
          <li><ScrollLink to="/community#forums" smooth>Forums</ScrollLink></li>
          <li><ScrollLink to="/community#webinars" smooth>Webinars</ScrollLink></li>
          <li><ScrollLink to="/community#seminars" smooth>Seminars</ScrollLink></li>
          <li><ScrollLink to="/community#trade-shows" smooth>Trade Shows</ScrollLink></li>
        </ul>
      </div>
    </div>
  );
};

export default LinkList;