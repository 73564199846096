// OurServices.js

import React from 'react';
import '../../assets/styles/ourservices.css';
import ProductListingIcon from '../../assets/images/ourservice/productlisting.svg';
import ConnectionIcon from '../../assets/images/ourservice/connection.svg';
import OurServicesPhone from '../../assets/images/ourservice/phoneS.png';
import SmartIcon from '../../assets/images/ourservice/smartmatching.svg';
import MarketInfoIcon from '../../assets/images/ourservice/marketinfo.svg';


const OurServices = () => {
  return (
    <div className="our-services">
      <div>
        <div className='header'>
          <h1> Smart <span>Solutions </span>for Agri-Trade Success</h1>
          <div className='desc-text'>Empowering agri-trade through cutting-edge solutions, Farmsell creates a smarter, more connected marketplace for all</div>
        </div>
        <div className='content'>
          <div className="column">
            <div className='element'>
              <div className="icon right">
                <img src={ProductListingIcon} alt='producticon' />
              </div>
              <div className="text right">
                <h2>Product Listing</h2>
                <div className='p'>Farmsell, your premier digital marketplace, empowers sellers to seamlessly list their products, creating a vibrant online storefront. Buyers, in turn, enjoy the flexibility to post and find products effortlessly. This service not only adheres to online marketplace best practices but also provides users with a user-friendly environment, optimizing their exploration and transactions</div>
              </div>
            </div>
            <div className='element lower'>
              <div className="icon right">
                <img src={SmartIcon} alt='smarticon' />
              </div>
              <div className="text right">
                <h2>Smart Matching</h2>
                <div className='p'>Farmsell takes a leap into the future with our Smart Matching service. Leveraging advanced AI, Farmsell intelligently matches sellers with buyers, ensuring swift and precise connections. This innovative feature streamlines the matchmaking process and enhances the efficiency of transactions. Farmsell's Smart Matching aligns with the forefront of industry trends, setting the standard for intelligent and effective connections in the agricultural marketplace.</div>
              </div>
            </div>
          </div>

          <div className="phoneimage">
            <img src={OurServicesPhone} alt="Service phone" />
          </div>

          <div className="column">
            <div className='element'>
              <div className="icon">
                <img src={ConnectionIcon} alt='connection' />
              </div>
              <div className="text">
                <h2>Connections</h2>
                <div className='p'>Farmsell recognizes that successful business is built on relationships. Beyond transactions, Farmsell facilitates connections between buyers and sellers, fostering a thriving community. Whether you seek reliable suppliers or long-term business partners, Farmsell serves as a hub for like-minded individuals. This service is essential for creating a resilient agricultural community that thrives on collaboration.</div>
              </div>
            </div>
            <div className='element lower'>
              <div className="icon">
                <img src={MarketInfoIcon} alt='marketinfo' />
              </div>
              <div className="text">
                <h2>Market Information</h2>
                <div className='p'>Farmsell's robust market information service offers real-time insights into product prices, availability, and essential details. This transparency empowers users to make informed decisions, ensuring every transaction is grounded in data. Our commitment to comprehensive market information aligns seamlessly with industry best practices, providing users with confidence as they navigate the agricultural marketplace.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
