import React from 'react';
import '../../assets/styles/whyfarmsell.css'; 
import Image1 from '../../assets/images/Hero/card1.png'; 
import Image2 from '../../assets/images/Hero/card2.png'; 
import Image3 from '../../assets/images/Hero/card3.png'; 

const WhyFarmsell = () => {
  const cards = [
    {
      title: 'Direct Market Access',
      description:
        'Cut out middlemen and long market trips. Farmsell connects farmers, hotels, and industries directly with buyers, reducing post-harvest losses and boosting incomes. Your market, at your fingertips.',
      image: Image1,
    },
    {
      title: 'Agriculture-Only Platform',
      description:
        'Farmsell is built exclusively for agriculture. Unlike generic B2B platforms, we streamline bulk trades for farmers, hotels, and Agro-industries, tailored to the sector’s unique needs.',
      image: Image2,
    },
    {
      title: 'Smart Matching',
      description:
        'Powered by AI, Farmsell’s Smart Matching pairs the right buyers with the right sellers, ensuring faster, efficient, and precise transactions. Smarter deals, every time.',
      image: Image3,
    },
  ];

  return (
    <div className="whyFarmsell-container">
      <h1 className="section-title">Why Farmsell? <span>Transforming</span> <br />
      Agriculture, One <span>Transaction</span> at a Time </h1>
    
      <div className="cards">
        {cards.map((card, index) => (
          <div key={index} className="Singlecard">
            <div className="card-image">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-contents">
              <h3 className="card-titles">{card.title}</h3>
              <p className="card-descriptions">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyFarmsell;
