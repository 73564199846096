import React from 'react'
import engImage from '../../assets/images/Rectangle 3844.svg'
import '../../assets/styles/Engagement.css'
export default function Engagements() {
  return (
    <div className='Eng-c'>
        <div className='Eng-w'>
            <div className='Eng-text'>


                <div className='Eng-heading' ><h2 className='Eng-h'>
                Community <span className='bg-text'>Engagement:</span> Cultivating Connections <span className='bg-text'>Beyond Transactions</span> 
                </h2></div>
                <div className='Eng-para'><p className='Eng-p'>At Farmsell, we don't just connect buyers with sellers; we unite a community of passionate individuals and businesses committed to transforming agriculture into a powerhouse of sustainability and prosperity. Our commitment goes beyond being a digital marketplace; we are the catalyst for positive change in the agricultural sector, fostering a sense of unity, knowledge-sharing, and empowerment.</p></div>
            </div>
            <div className='Eng-img'>
                <img className='Eng-images' src={engImage} alt='Engimage'/>
            </div>

        </div>

    </div>
  )
}
