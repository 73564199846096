import React from 'react';
import closeIcon from '../../assets/images/Closeds.png';
import EmailTake from '../../assets/images/Email(1).png';
import '../../assets/styles/Emailtaken.css';

const EmailTaken = ({toggle}) => {
  return (
    <div className="email-taken-container">
      <img src={closeIcon} alt="Close" className="close-icon" onClick={toggle} />
      <div className="contentvc">
        <div >
          <img src={EmailTake} alt="Close" className="closeEmail" />
        </div>
        <h2 className="take">Email taken!</h2>
        <p className="para">
          Oops! It seems like this email address is already registered with us. Would you prefer to change to a different email?
        </p>
        <a onClick={toggle} className="buttonnex">Yes, change</a>
        <p className="paraxx">
          Need Help? <a href="/contact-us" className="emai" style={{textDecoration:'none'}}>Chat with Us</a>
        </p>
      </div>
    </div>
  );
};

export default EmailTaken;
