import React from 'react'
import '../../assets/styles/header.css';
import { useState } from 'react';
import Logo from '../../assets/images/Header.svg';
import HamburgerIcon from '../../assets/images/Menu.svg';
// import Exi from '../../assets/images/exi.svg';
import { useNavigate } from 'react-router';
import Sidebar from '../HarmbugMenu';
// import { BrowserRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Link as ScrollLink } from 'react-scroll';
export default function CommunityHeader() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  // const forumsRef = useRef(null);
  const redirectToPage = redirect => {
    navigate(redirect);

  };
  // const How = () => {
  //   navigate('/HowItWorks');
  // };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  // const handleClick = () => {
  //   forumsRef.current.focus();
  // }
  return (

    <div className="our-header">

      <Sidebar isMenu={menuOpen} setMenu={() => toggleMenu()} />
      <header className="main-header shadow header-main-web">
        <div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
            <Link to="/">
              <img
                src={Logo}
                alt="logo"
                className="logo"
                style={{ width: '98%', cursor: 'pointer' }}
              />
            </Link>
            | <span className='bg-text' style={{ fontSize: "25px", fontWeight: "600",paddingRight: '12%' }}>Community</span>
          </div>
          <nav className="nav" style={{ marginLeft: '99px' }}>
          <ul>
          <ScrollLink to="forums" smooth duration={800} offset={-100}><li>Forums</li></ScrollLink>
          <ScrollLink to="webinars" smooth duration={900} offset={-100}><li>Webinars</li></ScrollLink>
          <ScrollLink to="seminars" smooth duration={800} offset={-100}><li >Seminars</li></ScrollLink>
          <ScrollLink to="trade-shows" smooth duration={800} offset={-100}><li >Trade Shows</li></ScrollLink>
        </ul>
          </nav>
          <button
            className="join-btn web"
            onClick={() => redirectToPage('/')}

          >
             Home 
          </button>
          <button className="join-btn mobile">Main Page</button>
          <img
            src={HamburgerIcon}
            alt="hamburger"
            className="hamburger"
            onClick={toggleMenu}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </header>

      <header className="main-header shadow header-main-mobile">
        <div className="header-mobile-home-container">
          <img
            src={Logo}
            alt="logo"
            className="logo"
            style={{ width: '140px' }}
          />

          <button
            className="join-btn mobile"
            style={{ marginLeft: 'auto' }}
            onClick={() => redirectToPage('/join-farmsell')}
          >
            farmsell.org
          </button>
          <img
            src={HamburgerIcon}
            alt="hamburger"
            style={{ height: 18, marginLeft: 8 }}
            onClick={toggleMenu}
          />
        </div>
      </header>
    </div>

  )
}
