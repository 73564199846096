import '../../assets/styles/RegPage.css';
import HeaderImage from '../../assets/images/Header.svg';
import Shadow from '../../assets/images/Shadow.png';
import Shadow2 from '../../assets/images/Shadow (1).png';
import Sidebar from '../HarmbugMenu';
import HamburgerIcon from '../../assets/images/Menu.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import navigateToTopPage from '../../shared/navigateTop';

const Welcomex = () => {
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const [isMenu, openMenu] = useState(false);
    
    const redirectToHomePage = () => {
      navigate('/join-farmsell'); // Use navigate instead of history.push
    };

  const openSideMenu = () => {
    openMenu(!isMenu);
  };

  const Video = 'https://farmsell.org/API/Welcome-General.mp4';

  useEffect(() => {
    navigateToTopPage();

    const video = document.getElementById('videoTag');

    if (video) {
      video.muted = true;
      video.play();
    }
  }, []);

  return (
    <div>
      <Sidebar isMenu={isMenu} setMenu={() => openSideMenu()} />
      <div
        className="header-join-container"
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: 100,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >
        <div
          className="join-header-icon"
          onClick={() => redirectToHomePage()}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={HeaderImage}
            className="logo-header"
            alt="Join farmsell Icon"
          />
        </div>
        <button
          className="join-btn-page"
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          onClick={() => redirectToHomePage()}
        >
          Back
        </button>
        <div className="harmburg-icon-container">
          <img
            src={HamburgerIcon}
            alt="hamburger"
            className="hamburger-join-farmsell"
            onClick={() => openSideMenu()}
          />
        </div>
      </div>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '-21%',
          }} className='kledsa'
        >
          <video
            style={{
              marginTop: '39%',
              width: '50%',
              height: '50%',
              marginLeft: '15%',
            }} className='nmelsdex'
            autoPlay
            controls
            id="videoTag"
          >
            <source src={Video} type="video/mp4" />
            <source src={Video} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
          <span
            className="linear-color"
            style={{
              marginTop: '-7%',
              marginLeft: '-50%',
              fontWeight: '600',
              fontSize: 45,
              
            }} id='sedcxs'
          >
            Welcome to Farmsell!
          </span>
          <p className="hey3">
            We're excited to have you with us. Could you take a moment to watch a special message
            <br />
            from our Founder? Your attention means a lot to us. Thanks a bunch!
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            src={Shadow2}
            style={{
              marginTop: '-15%',
              width: '40%',
              height: '40%',
              marginLeft: '5%',
              display: 'none',
            }}
          />
          <img
            src={Shadow}
            style={{
              marginTop: '-15%',
              width: '40%',
              height: '40%',
              marginLeft: '-25%',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Welcomex;
