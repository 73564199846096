import React from 'react';
import '../../assets/styles/VerifyEmail.css';
import VerificationInput from 'react-verification-input';

const VerifyEmail = ({
  submitVerification,
  verify,
  error,
  isResend,
  verifyEmail,
  firstName
}) => {
  return (
    <div>
      {error &&
        <div style={{ width: '100%', textAlign: 'center' }}>
          {' '}<span className="custom-validation">{error}</span>
        </div>}
      <div className="verify-email-container">
        <div className="verify-email-content">
          <h2 className="verify-email-header">Verify Email</h2>
          <p className="verify-email-text">
            Thanks for registering,<span style={{ fontWeight: 'bold' }}> {firstName}</span>! Check your inbox (not junk/spam) for the verification code to complete your registration process.
          </p>

        </div>
        <div className="combinedInput">
          <div className="verify-email-input-container">
            <VerificationInput
              length={6}
              validChars={'0-9'}
              placeholder=""
              onChange={e => submitVerification(e)}
              autoFocus={true}
              className="custom-verification-input"
            />
          </div>
          <button
            disabled={verify !== 'Verify'}
            className="verify-email-button"
          >
            {verify}
          </button>
        </div>

      </div>

      <div className='vrtyt'
        style={{ display: 'flex', marginTop: 20, marginBottom: 20, padding: 20 }}
      >
        <p className="verify-email-subheade">
          Need Help?{" "}
          <a href="/contact-us#contactForm" className="ema" style={{ cursor: "pointer", textDecoration: "none" }}>
            Chat with Us
          </a>
        </p>

        <p
          className="verify-email-foote"
          style={{ marginLeft: 'auto' }}
          onClick={verifyEmail}
        >
          Din’t see code?
          {' '}
          <a href='#' className="emai" style={{ cursor: 'pointer' }}>
            {!isResend ? 'Resend it' : 'Resending....'}
          </a>
        </p>
      </div>
    </div>
  );
};

export default VerifyEmail;
