import Logo from '../../assets/images/Logo Farmsell.svg';
import Apple from '../../assets/images/Apple.svg';
import Google from '../../assets/images/Google Play.svg';
import LinkList from './linkList';
import Linkedin from '../../assets/images/Linkedin.png';
import Tube from '../../assets/images/Youtube.png';
import FB from '../../assets/images/facebook.png';
import Twitter from '../../assets/images/Twitter X.png';
import Arrow from '../../assets/images/Arrow.png';

import {Link} from 'react-router-dom';
import navigateToNewTab from '../../shared/newTabRedirect';

const FirstFooter = () => {
  const twitterProfileUrl = 'https://twitter.com/farmsellApp'
  const fbProfileUrl = 'https://www.facebook.com/farmsellapp'
  const ytProfileUrl = 'http://youtube.com/@farmsellApp'
  const linkProfileUrl = 'https://www.linkedin.com/company/farmsellapp/'
  const redirectToStore = url => {
    navigateToNewTab (url);
  };

  return (
    <div className="firstFooterContainer">

      <div className='logo_container'>

      <div className="img-container">
        <Link to="/">
          <img src={Logo} alt="Farmsell Logo" className="img-logo" />
        </Link>
      </div>

      <div className='media-links'>

      <a href={linkProfileUrl} target="_blank" rel="noopener noreferrer">
          <div className="icon-circle">
            <img src={Linkedin} alt="Farmsell IOS Icon" />
          </div>
        </a>


        <a href={ytProfileUrl} target="_blank" rel="noopener noreferrer">
          <div className="icon-circle">
            <img src={Tube} alt="Farmsell IOS Icon" />
          </div>
        </a>


        <a href={fbProfileUrl} target="_blank" rel="noopener noreferrer">
          <div className="icon-circle">
            <img src={FB} alt="Farmsell IOS Icon" />
          </div>
        </a>


        <a href={twitterProfileUrl} target="_blank" rel="noopener noreferrer">
          <div className="icon-circle">
            <img src={Twitter} alt="Farmsell IOS Icon" />
          </div>
        </a>
        
      </div>

      </div>

      
      <LinkList />

      <div className="download-container">
        <h4 className="download-header">Download Farmsell app now</h4>
        <div style={{marginTop: -8, fontSize: 15, color: 'white'}}>
          Join Farmsell and be part of a transformative journey
        </div>

        <div
          className="ios-download"
          style={{cursor: 'pointer'}}
          onClick={() =>
            redirectToStore (
              'https://apps.apple.com/ug/app/farmsell/id6447581635'
            )}
        >
          <img
            style={{marginLeft: '-15%'}}
            src={Apple}
            alt="Farmsell IOS Icon"
          />
          <div style={{marginLeft: '5%'}}> Download for iOS</div>
        </div>

        <div
          className="android-download"
          onClick={() =>
            redirectToStore (
              'https://play.google.com/store/apps/details?id=com.farmsell&hl=en&gl=US'
            )}
          style={{cursor: 'pointer'}}
        >
          <img src={Google} alt="Farmsell IOS Icon" />
          <div style={{color: 'white', whiteSpace: 'nowrap', marginLeft: '2%'}}>
            Download for Android
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstFooter;
