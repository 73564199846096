import React, { useState, useEffect } from 'react';
import '../../assets/styles/header.css';
import Logo from '../../assets/images/farmselllogo.svg';
import HamburgerIcon from '../../assets/images/Menu.svg';
import { useNavigate } from 'react-router';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showHeader, setShowHeader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setShowHeader(currentScrollPos < prevScrollPos || currentScrollPos === 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const redirectToPage = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className={`main-header ${showHeader ? 'show' : 'hide'}`}>
      <div className="header-container">
        {/* Logo */}
        <img
          src={Logo}
          alt="Farmsell Logo"
          className="logo"
          onClick={() => redirectToPage('/')}
        />

        {/* Desktop Navigation */}
        <nav className="nav">
          <ul className="nav-list">
            <li onClick={() => redirectToPage('/')}>Home</li>
            <ScrollLink to="service" smooth duration={900}>
              <li>Services</li>
            </ScrollLink>
            <li onClick={() => redirectToPage('/how-we-work')}>How We Work</li>
            <li onClick={() => redirectToPage('/about-us')}>About Us</li>
          </ul>
        </nav>

        {/* Desktop Button */}
        <button
          className="join-btn desktop"
          onClick={() => redirectToPage('/join-farmsell')}
        >
          Join Now, It’s Free
        </button>

        {/* Mobile Menu Toggle */}
        <div className="hamburger-container" onClick={toggleMenu}>
          <img src={HamburgerIcon} alt="Menu Icon" className="hamburger-icon" />
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="mobile-menu">
          <ul className="mobile-nav-list">
            <li onClick={() => redirectToPage('/')}>Home</li>
            <ScrollLink to="service" smooth duration={900} onClick={toggleMenu}>
              <li>Services</li>
            </ScrollLink>
            <li onClick={() => redirectToPage('/how-we-work')}>How We Work</li>
            <li onClick={() => redirectToPage('/about-us')}>About Us</li>
          </ul>
          <button className="close-btnhead" onClick={toggleMenu}>
            Close
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
