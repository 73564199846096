import React from 'react'
import '../../assets/styles/Seminars.css'
import ButtonArrow from '../../assets/images/welcomebuttonarrow.svg';

import seminarImage from '../../assets/images/11904 [Converted]-01 1.svg'
export default function Seminars() {
  return (
    <div className='s-c' id='seminars'>


        <div className='s-wrapper'>

            <div className='s-header'>
    
               <h2 className='heading'>
                <span className='bg-text'>Seminars/Workshops</span><br/>
               <span style={{color:"#274C5B"}}> Bridging Gaps, Building Futures</span>
                </h2>
               
            </div>
            <div className='s-w'>
                <div className='s-img'>
                    <img className='s-images' src={seminarImage} alt='forumImage'/>
                </div>
                <div className='s-text'>
                  <div className='s-para'>  <p className='s-p'>
                    Transform your skills and deepen your understanding through Farmsell's exclusive seminars and workshops. Designed as immersive 2-5 day experiences, these sessions address specific gaps in your knowledge, offering hands-on training that empowers you to thrive in the agricultural landscape. Our seminars are conducted bi-annually or quarterly, ensuring you stay at the forefront of industry developments. Secure your spot and invest in your agricultural future

                    </p></div>
                 <div className='s-btnw'>   <button
                    className='s-btn'

        >
          Reserve Your Seat
          <span className=''>
            <img src={ButtonArrow} alt='buttonarrow' />
          </span>
        </button></div>
                </div>

            </div>
        </div>
    </div>
  )
}
