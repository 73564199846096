import React, { useState } from 'react';
import '../../../assets/styles/about-us/fundingPrinciple.css';
import ArrowUp from '../../../assets/images/arrowup.svg';
import ArrowDown from '../../../assets/images/arrowdown.svg';
import Funding1 from '../../../assets/images/aboutus/funding1.jpg';



const FundingPriciple = () => {
  const [openSection, setOpenSection] = useState(0);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? index : index);
  };

  const sections = [
    {
      title: 'Empowerment Through Connectivity',
      content: "Farmsell's commitment extends beyond transactions to empowerment. By fostering seamless connectivity, we bridge the gaps between farmers, agri-input enterprises, institutions, and industries. Our platform acts as the catalyst for a united ecosystem where mutual benefits flourish",
      img: Funding1
    },
    {
      title: 'Innovation in Agriculture',
      content: "Empowering Your Harvest, Empowering Your Life Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs. Bid farewell to exploitation and market uncertainties as Farmsell becomes your reliable companion, ensuring your produce reaches the right buyers and your farm is equipped with the best tools for success.",
      img: Funding1
    },
    {
      title: 'Transparency and Fair Trade',
      content: "Empowering Your Harvest, Empowering Your Life Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs. Bid farewell to exploitation and market uncertainties as Farmsell becomes your reliable companion, ensuring your produce reaches the right buyers and your farm is equipped with the best tools for success.",
      img: Funding1
    },
    {
      title: 'Elimination of Middlemen',
      content: "Empowering Your Harvest, Empowering Your Life Smallholder farmers, with Farmsell, your journey takes a transformative turn. Gain direct access to both buyers for your farm produce and reputable agri-input enterprises offering high-quality tools and inputs. Bid farewell to exploitation and market uncertainties as Farmsell becomes your reliable companion, ensuring your produce reaches the right buyers and your farm is equipped with the best tools for success.",
      img: Funding1
    },
  ];

  return (
    <div className="tailored-benefits">
      <div className='header'>  
        <h2>Founding <span className='colored'>Principles.</span><br />
        Sowing Seeds of <span className='colored'>Transformation</span></h2>
        
        <div className='desc-text'>Embark on a journey with Farmsell, where connectivity isn't just a feature—it's a force for empowerment. Discover our commitment to innovation, transparency, fair trade, and the elimination of middlemen, transforming agricultural trading into an unyielding ecosystem.
        </div>

      </div>
      <div className='content'>
        <div>
          {sections.map((section, index) => (
            <div key={index} className='element'>
              <div
                className="accordion-toggle"
                onClick={() => toggleSection(index)}
                aria-expanded={openSection === index}
              >
                {section.title}
                {openSection === index ? (
                  <img className='icon' src={ArrowUp} alt='arrowup' />
                ) : (
                  <img className='icon' src={ArrowDown} alt='arrowdown' />
                )}
              </div>
              <div
                className={`accordion-content ${openSection === index ? 'show' : ''
                  }`}
              >
                <div>
                  <p>{section.content}</p>
                  <div className='phone-image'>
                    <img
                      src={section.img}
                      alt={`Visual aid for ${section.title}`}
                      className={`accordion-image ${openSection === index ? 'show' : 'hide'
                        }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='destop-image'>
          <img src={sections[openSection].img} alt='imageaid' />
          {/* <img src={sections[openSection].img} alt='imageaid' /> */}
        </div>
      </div>

    </div>
  );
};

export default FundingPriciple;