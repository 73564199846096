import React from 'react';
import '../../assets/styles/howitWorks2.css';
import StepsFlow from '../../assets/images/illustrate process.svg';
import Header from '../Header';
import Footer from '../Footer';
import StepsComponent from './StepsComponent';
import StepsComponent2 from './StepsComponent2';
import step1 from '../../assets/images/HowItWorks/step1.svg';
import step2 from '../../assets/images/HowItWorks/step2.png';
import step3 from '../../assets/images/HowItWorks/step3.svg';
import step4 from '../../assets/images/HowItWorks/step4.svg';
import step5 from '../../assets/images/HowItWorks/step5.svg';
import step6 from '../../assets/images/HowItWorks/step6.svg';
import step7 from '../../assets/images/HowItWorks/step7.jpg';
import step8 from '../../assets/images/HowItWorks/step8.svg';
import JoinFarmsell from '../JoinFarmsell/joinFarmsell';
import MobileSteps from './MobileSteps';
import TestimonialPage from '../Testimonies';
import { Helmet } from 'react-helmet';

const HowItWorks = () => {
  return (
    <div>
      <Helmet>
      <title>Farmsell: How we Work</title>
        <meta name="description" content="Explore the inner workings of Farmsell, where simplicity meets success in B2B 
        agricultural transactions. Our step-by-step process illustrates how our user-friendly platform seamlessly connects 
        buyers with sellers, streamlining the entire process. Experience the future of agribusiness – start your journey with 
        Farmsell Today."/>
      </Helmet>


      <Header />
      <main />
      <div className="hero-how-works">
        <div className="container-content">

          <div className="text-cont">
            <h3 className="how-title">
              Harvesting
              {' '}
              <span className="co">Success:</span>
              {' '}
              Unveiling the Simplest
              {' '}
              <span className="co">Agricultural</span>
              {' '}
              Transactions with Farmsell
            </h3>
            <p className="how-para">
              From Seed to Sale: Effortless Process for Transparent, Efficient, and Profitable Transactions"
            </p>
          </div>
          <div className="image-cont">
            <img className="img-how" src={StepsFlow} alt='process steps flow'/>
          </div>

        </div>
      </div>
      <div className="mobile-view-steps">
        <MobileSteps />
      </div>

      <div className="main-container">
        {/* <div className='vertical-line'></div> */}
        <StepsComponent
          imageSrc={step1}
          title="Step 1"
          sub_title="Registration"
          description="The sellers and buyers register on the Farmsell platform and create their profiles. 
          They provide information such as their location, contact details, product preferences, and payment methods."
        />
        {/* <div className="line-container">
          <div className="vertical-line">
            <div className="circle"></div>
          </div>
        </div> */}
        <StepsComponent2
          imageSrc={step2}
          title="Step 2"
          sub_title="Product  Listing"
          description="The sellers list their products on the platform and provide details such as the quantity,
           quality, price, and availability of the products. They can also upload photos or videos of the products to showcase their features."
        />

        <StepsComponent
          imageSrc={step3}
          title="Step 3"
          sub_title="Matching"
          description="The Farmsell platform uses AI to match the sellers and buyers based on their profiles and preferences. 
          The platform notifies the sellers and buyers of the potential matches and allows them to view each other's profiles and products."
        />

        <StepsComponent2
          imageSrc={step4}
          title="Step 4"
          sub_title="Negotiation"
          description="The sellers and buyers communicate with each other through the platform and negotiate the terms and 
          conditions of the transaction. They can also request or provide additional information or verification of the products."
        />

        <StepsComponent
          imageSrc={step5}
          title="Step 5"
          sub_title="Confirmation"
          description="The sellers and buyers confirm the transaction and agree on the payment and delivery methods. The Farmsell platform 
          generates a contract and an invoice for the transaction and sends them to both parties."
        />

        <StepsComponent2
          imageSrc={step6}
          title="Step 6"
          sub_title="Payment"
          description="The buyers pay for the products through the platform using their preferred payment method. The Farmsell platform holds
           the payment in escrow until the delivery is confirmed"
        />

        <StepsComponent
          imageSrc={step7}
          title="Step 7"
          sub_title="Delivery"
          description="The sellers deliver the products to the buyers using their preferred delivery method. 
          The Farmsell platform tracks the delivery status and notifies both parties of the progress."
        />

        <StepsComponent2
          imageSrc={step8}
          title="Step 8"
          sub_title="Completion"
          description="The buyers receive the products and confirm the delivery through the platform. 
          The Farmsell platform releases the payment to the sellers and closes the transaction. 
          The sellers and buyers rate and review each other and the products on the platform"
        />

      </div>

      <TestimonialPage />
      <JoinFarmsell />
      <main/>

      <Footer />

    </div>
  );
};

export default HowItWorks;
