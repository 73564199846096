import React, { useState, useEffect } from "react";
import MessageIcon from "../../assets/images/contactus/messageicon.svg";
import PhoneIcon from "../../assets/images/contactus/phoneicon.svg";
import LinkedIn from "../../assets/images/contactus/Linkedin.svg";
import FaceBook from "../../assets/images/contactus/facebook.svg";
import YouTube from "../../assets/images/contactus/Youtube.svg";
import Exi from "../../assets/images/contactus/exi.svg";
import Arrow from "../../assets/images/welcomebuttonarrow.svg";
import Location from "../../assets/images/contactus/location.svg";
import Request from "../../utils/requests";
import ContactPopup from "../../components/ContactUs/ContactPopup";

const GetinTouch = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            // Form is valid, you can proceed with form submission

            setErrors({});


            const body = {
                name: formData.firstName + ' ' + formData.lastName,
                ...formData
            }


            Request.postMethod('contact-us', body)

                .then(response => {
                    if (response.data.message) {
                        setFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            phoneNumber: '',
                            subject: '',
                            message: ''
                        });
                        setIsPopupOpen(true); // Open the popup
                    }

                    

                })
                .catch(error => {
                    if (error)
                        alert('Something went wrong, please try again');
                })
            // Send email to sell@farmsell.org
            // try {
            //     await sendEmail(formData);
            // } catch (error) {
            //     console.error('Error sending email:', error);
            // }

            // Send automatic message to the person who submitted the form
            // try {
            //     await sendAutomaticMessage(formData.email);
            // } catch (error) {
            //     console.error('Error sending automatic message:', error);
            // }

            //alert('Form submitted successfully.');
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        const errors = {};
        // Regular expression for phone number validation
        const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

        if (!data.firstName.trim()) {
            errors.firstName = 'First Name is required';
        } else if (data.firstName.length < 2) {
            errors.firstName = 'First Name must be at least 2 characters long';
        }

        if (!data.lastName.trim()) {
            errors.lastName = 'Last Name is required';
        } else if (data.lastName.length < 2) {
            errors.lastName = 'Last Name must be at least 2 characters long';
        }

        if (!data.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }

        if (!data.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone Number is required';
        } else if (!phoneRegex.test(data.phoneNumber)) {
            errors.phoneNumber = 'Phone Number is invalid';
        }

        if (!data.subject.trim()) {
            errors.subject = 'Subject is required';
        }

        if (!data.message.trim()) {
            errors.message = 'Message is required';
        }

        return errors;
    };

    // const scrollToForm = (e) => {
    //     e.preventDefault();
    //     const form = document.getElementById("contactForm");
    //     if (form) {
    //         form.scrollIntoView({
    //             behavior: "smooth",
    //             block: "start",
    //             inline: "nearest",
    //         });
    //     }
    // };

    useEffect(() => {
        const handleContactUsClick = () => {
            const firstNameSection = document.getElementById("first-name-section");
            const offset = 300; // Adjust this value to set the height limit
            if (firstNameSection) {
                const top = firstNameSection.offsetTop - offset;
                window.scrollTo({ top: top, behavior: "smooth" });
            }
        };
    
        const contactUsLink = document.getElementById("contact-us-link");
        if (contactUsLink) {
            contactUsLink.addEventListener("click", handleContactUsClick);
        }
    
        return () => {
            if (contactUsLink) {
                contactUsLink.removeEventListener("click", handleContactUsClick);
            }
        };
    }, []);
    

    return (
        <div className="get-container">
            <div className="sect-one">
                <div className="section-content">
                    <h3>Get in <span>touch</span> with us</h3>
                    <div className="desc-text-sect1">
                        Do you have questions or ideas?
                        We'd love to hear from you
                        so that we can personalize
                        the right experience for you.
                    </div>
                    <div className="items">
                        <div className="item">
                            <div className="icon">
                                <img src={MessageIcon} alt="message icon" />
                            </div>
                            <div className="name">Mail Us</div>
                            <div className="src">sell@farmsell.org</div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src={PhoneIcon} alt="phone icon" />
                            </div>
                            <div className="name">Call Us</div>
                            <div className="src">+256200908088</div>
                        </div>
                        <div className="item last">
                            <div className="icon">
                                <img src={Location} alt="location icon" />

                            </div>
                            <div className="name">Visit Us</div>
                            <div className="src">Plot 280, Block 244. <br />Bujjuko, Wakiso District</div>
                        </div>

                        <div className="social-media-icons" style={{ display: 'none' }}>
                            <ul>
                                <li>
                                    <img src={LinkedIn} alt="linkein" />
                                </li>
                                <li>
                                    <img src={YouTube} alt="youtub" />
                                </li>
                                <li>
                                    <img src={FaceBook} alt="fcebook" />
                                </li>
                                <li>
                                    <img src={Exi} alt="exi" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sect-two">
                <div className="cont-form">
                <form id="contactForm" onSubmit={handleSubmit} >
                        <div className="element">
                            <label>Your Name*</label>
                            <div>
                                <input type="text" name="firstName" className="one" placeholder="First Name" value={formData.firstName} onChange={handleChange} />
                                <input type="text" name="lastName" className="two" placeholder="Second name" value={formData.lastName} onChange={handleChange} />
                                {errors.firstName && <p className="error-message">{errors.firstName}</p>}
                                {errors.lastName && <p className="error-message">{errors.lastName}</p>}
                            </div>
                        </div>
                        <div className="element">
                            <label>Your Email*</label>
                            <div>
                                <input type="email" name="email" placeholder="example@gmail.com" value={formData.email} onChange={handleChange} />
                                {errors.email && <p className="error-message">{errors.email}</p>}
                            </div>
                        </div>
                        <div className="element">
                            <label>Phone Number*</label>
                            <div>
                                <input type="tel" name="phoneNumber" placeholder="+245  000 0000  00000" value={formData.phoneNumber} onChange={handleChange} />
                                {errors.phoneNumber && <p className="error-message">{errors.phoneNumber}</p>}
                            </div>
                        </div>
                        <div className="element">
                            <label>Subject</label>
                            <div>
                                <input type="text" name="subject" placeholder="How can we help you" value={formData.subject} onChange={handleChange} />
                                {errors.subject && <p className="error-message">{errors.subject}</p>}
                            </div>
                        </div>
                        <div className="element">
                            <label>Message</label>
                            <div>
                                <textarea name="message" placeholder="Hello there, I would like to talk about how to..." value={formData.message} onChange={handleChange}></textarea>
                                {errors.message && <p className="error-message">{errors.message}</p>}
                            </div>
                        </div>
                        <div className="send-button">
                            <button type="submit">Submit <img src={Arrow} alt="arrow" /></button>
                        </div>
                    </form>
                </div>
            </div>
            <ContactPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
        </div>
    );
};

export default GetinTouch;
