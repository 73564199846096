
import { Navbar, Nav, Button, CloseButton } from 'react-bootstrap';
import '../../assets/styles/RegPage.css';
import HeaderImage from '../../assets/images/Header.svg';
import { useNavigate, useParams } from 'react-router';
import Vec from '../../assets/images/Google.svg';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useGoogleLogin } from '@react-oauth/google';
import Sidebar from '../HarmbugMenu';
import HamburgerIcon from '../../assets/images/Menu.svg';
import navigateToTopPage from '../../shared/navigateTop';
import axios from 'axios';
import constants from '../../utils/constants';

import MoonLoader from 'react-spinners/MoonLoader';

import Modal from 'react-modal';
import VerifyEmail from './VerifyEmail';
import EmailTaken from './Emailtaken';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '50%',
    display: 'flex',
    zIndex: 99999999999999999999999999999,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const customStylesTaken = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '50%',

    zIndex: 99999999999999999999999999999,

    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

// import PhoneInput from 'react-phone-number-input/input';

const Registration = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const { name } = useParams();
  // const [value, setValue] = useState ();
  const [isMenu, openMenu] = useState(false);
  const [isResend, setResend] = useState(false);
  const [isEmailTaken, setIsEmailTaken] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const [verify, setVerify] = useState('Verify');

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    firstName: '',
    secondName: '',
  });
  const [token, setToken] = useState(false);

  const [joinBtn, setjoinBtn] = useState('Join Now');

  const [formError, setFormError] = useState({
    firstName: '',
    phone: '',
    email: '',

    lastName: '',
    secondName: '',
  });

  const getToken = () => {
    //const refresh_token = constants.refresh_token;

    const url = `https://accounts.zoho.com/oauth/v2/token?
    client_id=${constants.client_id}&
    grant_type=refresh_token&
    client_secret=${constants.client_secret}&
    refresh_token=${constants.refresh_token}`;

    axios
      .post(constants.productionUrl + 'get-zoho-token', {
        url: url.replace(/\s/g, ''),
      })
      .then(response => {
        //console.log (response, 'response....');
        const records = response.data;
        setToken(records.token);
      })
      .catch(error => {
        console.log(error, 'test');
      });
  };

  const joinFarmsellRedirect = () => {
    navigate('/join-farmsell');
  };

  const submitFormData = body => {
    axios
      .post(
        constants.productionUrl + 'save-zoho-contact',
        body
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      )
      .then(response => {
        setIsGoogleLoading(false);
        setjoinBtn('Join Now');
        setVerify('Verify');

        if (!response.data.status) {
          const info = response.data.message;
          setServerError(info);
          if (info.toLowerCase().includes('code')) {
          }
          if (info.toLowerCase().includes('email')) {
            setIsOpen(false);
            setIsEmailTaken(true);
            disableScroll(true);
          }
          return;
        }

        //console.log (response, 'response data...');
        //return;
        if (response.data.status) {
          localStorage.setItem('is_resgistered', 'registered');
          window.location.href = '/welcome';
        }
      })
      .catch(error => {
        setjoinBtn('Join Now');
        console.log(error, 'error');
      });
  };

  const disableScroll = (isEmailTaken) => {
    const global_scroll = document.getElementById('global_scroll');


    if (isEmailTaken) {
      global_scroll.style.overflow = 'hidden';
      return;
    }
    global_scroll.style.overflow = 'auto';

  }

  const redirectToHomePage = () => {
    navigate('/');
  };

  const openSideMenu = () => {
    openMenu(!isMenu);
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const access_token = tokenResponse.access_token;

      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then(res => res.data);

      let contactInfo = {};

      contactInfo['Contact Email'] = userInfo.email;
      contactInfo['Core Interest'] = name;
      contactInfo['Country'] = 'Uganda';
      contactInfo['Phone Number'] = 256788929921;
      contactInfo['First Name'] = userInfo.given_name;
      contactInfo['Last Name'] = userInfo.family_name;

      localStorage.setItem('first_name', userInfo.given_name);

      let encodedContactInfo = encodeURIComponent(
        JSON.stringify(contactInfo)
      );

      const url = `https://campaigns.zoho.com/api/v1.1/json/listsubscribe?resfmt=JSON&listkey=${constants.listCode}&contactinfo=${encodedContactInfo}`;

      const body = {
        url: url.replace(/\s/g, ''),
        token: token,
        google: true,
        first_name: userInfo.given_name,
        email: userInfo.email,
        last_name: userInfo.family_name,
      };

      setIsGoogleLoading(true);

      submitFormData(body);
    },
  });

  const addFormValue = (name, e) => {
    const data = { ...formData, ...{ [name]: e.target.value } };

    setFormData(data);
    setFormError({
      ...{
        name: '',
        phone: '',
        email: '',
        password: '',
      },
    });
    setServerError(false);
  };

  const addFormValuePhone = e => {
    const data = { ...formData, ...{ phone: e } };

    setFormData(data);
  };

  // const validateForm = () => {
  //   var status = false;

  //   const { firstName, phone, email, secondName } = formData;
  //   var dataError = {};

  //   if (!firstName) {
  //     status = true;
  //     dataError = { ...dataError, ...{ firstName: 'Name field is required' } };
  //   }
  //   if (!phone) {
  //     status = true;
  //     dataError = { ...dataError, ...{ phone: 'Phone field is required' } };
  //   }
  //   if (!email) {
  //     status = true;
  //     dataError = { ...dataError, ...{ email: 'Email field is required' } };
  //   }
  //   if (!secondName) {
  //     status = true;
  //     dataError = { ...dataError, ...{ secondName: 'Password field is required' } };
  //   }

  //   setFormError(dataError);

  //   return status;
  // };
  const validateForm = () => {
    var status = false;

    const { firstName, phone, email, secondName } = formData;
    var dataError = {};

    if (!firstName) {
      status = true;
      dataError = { ...dataError, ...{ firstName: 'First Name field is required' } };
    }
    if (!secondName) {
      status = true;
      dataError = { ...dataError, ...{ secondName: 'Second Name field is required' } };
    }
    if (!phone) {
      status = true;
      dataError = { ...dataError, ...{ phone: 'Phone field is required' } };
    }
    if (!email || !email.includes('@')) {
      status = true;
      dataError = { ...dataError, ...{ email: 'Email field is required and must include @' } };
    }

    setFormError(dataError);

    return status;
  };




  const verifyEmail = () => {
    const isNotValidated = validateForm();

    if (isNotValidated) {
      return;
    }

    setjoinBtn('Sending...');

    const { email, firstName } = formData;
    axios
      .post(constants.productionUrl + 'verify-zoho-email', {
        email,
        first_name: firstName,
      })
      .then(response => {
        const data = response.data;
        setjoinBtn('Join Now');
        setResend(false);
        if (!data.status) {
          //const errorData = {...formError, ...{email: data.message}};
          //setFormError (errorData);
          const info = data.message;

          if (info.toLowerCase().includes('email')) {
            setIsOpen(false);
            setIsEmailTaken(true);
          }

          setServerError(data.message);

          return;
        }
        setIsOpen(true);
      })
      .catch(error => {

        setServerError('Something went  wrong, Try again latter');
        setjoinBtn('Join Now');
      });
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const submitVerification = e => {
    if (e.length === 6) {
      const { email, phone, firstName, secondName } = formData;
      let contactInfo = {};

      contactInfo['Contact Email'] = email;
      contactInfo['Core Interest'] = name;
      contactInfo['Country'] = 'Uganda';
      contactInfo['Phone Number'] = phone;
      contactInfo['First Name'] = firstName;
      contactInfo['Last Name'] = secondName;

      localStorage.setItem('first_name', firstName);

      let encodedContactInfo = encodeURIComponent(
        JSON.stringify(contactInfo)
      );

      const url = `https://campaigns.zoho.com/api/v1.1/json/listsubscribe?resfmt=JSON&listkey=${constants.listCode}&contactinfo=${encodedContactInfo}`;

      setjoinBtn('Sending...');
      setVerify('Verifying....');

      const body = {
        url: url.replace(/\s/g, ''),
        token: token,
        code: e,
        first_name: firstName,
        email,
        last_name: secondName,
      };
      submitFormData(body);
    }
  };

  // function openModal() {
  //   setIsOpen(true);
  // }

  const toggleEmailTakenMessage = () => {


    disableScroll(!isEmailTaken);
    setIsEmailTaken(!isEmailTaken);


    setFormData({
      ...{
        name: '',
        phone: '',
        email: '',
        password: '',
        firstName: '',
        secondName: '',
      },
    });
    setServerError('');


  };

  useEffect(() => {

    //localStorage.setItem ('status', name);
    //localStorage.setItem ('is_resgistered', 'registered');
    getToken();
    navigateToTopPage();
    disableScroll(false);
  }, []);
  return (
    <div style={{ overflowX: 'hidden' }}>

      <Modal
        isOpen={isEmailTaken}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesTaken}
        contentLabel="Example Modal"
      >
        <EmailTaken toggle={toggleEmailTakenMessage} />

      </Modal>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: 10,
            marginBottom: 20,
          }}
        >
          {' '}
          <CloseButton
            aria-label="Close"
            style={{ marginLeft: 'auto' }}
            onClick={closeModal}
          />
        </div>
        <VerifyEmail
          verify={verify}
          error={serverError}
          submitVerification={submitVerification}
          verifyEmail={() => {
            verifyEmail();
            setResend(true);
          }}
          isResend={isResend}
          firstName={formData.firstName}
        />

      </Modal>

      <Sidebar isMenu={isMenu} setMenu={() => openSideMenu()} />

      <div
        className="header-join-container"
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: 100,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >

        <div
          className="join-header-icon"
          onClick={() => redirectToHomePage()}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={HeaderImage}
            className="logo-header"
            alt="Join farmsell Icon"
          />
        </div>
        <button
          className="join-btn-page"
          onMouseOver={e => (e.target.style.backgroundColor = '#F7B618')}
          onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
          onClick={() => redirectToHomePage()}
        >
          Back Home
        </button>

        <div className="harmburg-icon-container">
          <img
            src={HamburgerIcon}
            alt="hamburger"
            className="hamburger-join-farmsell"
            onClick={() => openSideMenu()}
          />
        </div>

      </div>

      {!modalIsOpen &&
        <div className="container-join-farmsell-page reg-header-mobile">
          <div className="join-page-heading-mobile">
            <h1
              style={{
                marginTop: 40,

                fontSize: 45,
              }}
            >

              Register

              {' '}
              <span className="linear-color"> here </span>
            </h1>
            <br />
            <div
              style={{
                marginTop: '-2%',

                fontSize: 25,
                color: '#274C5B',
                fontWeight: '500',
              }}
            >
              Mind sharing your contact details <br />

              with us
            </div>

            <div
              style={{
                marginTop: '6%',
                marginBottom: '6%',
              }}
            >

              <span
                style={{
                  color: '#274C5B',
                  fontSize: 18,
                  fontWeight: '700',
                }}
              >
                {' '}You are registering as a <span
                  style={{
                    color: '#3FB871',
                    fontSize: 18,
                    fontWeight: '700',
                  }}
                >
                  {name}
                </span>
              </span>
              <span
                style={{
                  marginTop: '20%',
                  color: '#F71818',
                  fontSize: 18,
                  fontWeight: '700',
                  marginLeft: 8,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => joinFarmsellRedirect()}
              >
                Change
              </span>

            </div>

          </div>   {' '}

          <div className="join-page-heading1">
            <h1
              style={{
                marginTop: 40,

                fontSize: 45,
              }}
            >

              Register

              {' '}
              <span className="linear-color"> here </span> <br />
              <br />

            </h1>

            <div
              style={{
                marginTop: '-2%',

                fontSize: 25,
                color: '#274C5B',
                fontWeight: '500',
              }}
            >
              Mind sharing your contact details <br />

              with us

            </div>

            <div
              style={{
                marginTop: '6%',
                marginBottom: '6%',
              }}
            >

              <span
                style={{
                  color: '#274C5B',
                  fontSize: 18,
                  fontWeight: '700',
                }}
              >
                {' '}You are registering as a <span
                  style={{
                    color: '#3FB871',
                    fontSize: 18,
                    fontWeight: '700',
                  }}
                >
                  {name}
                </span>
              </span>
              <span
                style={{
                  marginTop: '20%',
                  color: '#F71818',
                  fontSize: 18,
                  fontWeight: '700',
                  marginLeft: 8,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => joinFarmsellRedirect()}
              >
                Change
              </span>

            </div>

          </div>   {' '}
        </div>}

      <MoonLoader
        color={'green'}
        loading={isGoogleLoading}
        cssOverride={override}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {!isGoogleLoading &&
        <Button
          type="button"
          className="btn-google footer-style-reg"
          style={{ marginTop: '-6%' }}
          onClick={() => login()}
        >
          <img src={Vec} style={{ marginLeft: '0%' }} alt='vec' />
          <span
            style={{
              marginLeft: '2%',
              color: '#565656',

              fontFamily: 'Poppins,sans-serif',
              fontSize: '18px',

              fontWeight: '400',
              lineHeight: 'normal',
            }}
          >
            {' '}Join with Google
          </span>
        </Button>}

      {!modalIsOpen &&
        <div style={{ textAlign: 'center' }}>
          <span
            style={{ color: '#000000', fontSize: '14px', fontWeight: 'bold' }}
          >
            <hr className="reg-line" />
            OR
            <hr className="reg-line" />
          </span>
        </div>}

      {!modalIsOpen &&
        <div>
          <div className="input-form" style={{ marginTop: 0 }}>

            {formError.firstName && <span className="custom-validation">{formError.firstName}</span>}
            <input
              type="text"
              placeholder="Write your first name here"
              value={formData.firstName}
              className="input-custom-style"
              onChange={e => addFormValue('firstName', e)}
            />
            {formError.secondName && <span className="custom-validation">{formError.secondName}</span>}
            <input
              type="text"
              placeholder="Write your second name here"
              value={formData.secondName}
              className="input-custom-style"
              onChange={e => addFormValue('secondName', e)}
            />

            <input
              value={formData.email}
              type="email"
              className="input-custom-style"
              onChange={e => addFormValue('email', e)}
              placeholder="Write your email here"
            />
            {formError.email &&
              <span className="custom-validation">{formError.email}</span>}


            <PhoneInput
              country={'ug'}
              value={formData.phone}
              onChange={e => addFormValuePhone(e)}
              className="phone-input-field"
              containerStyle={{
                width: 422,
                marginBottom: 10,
                backgroundColor: '#f5f5f5',
              }}
              inputStyle={{ width: 422, height: 50, backgroundColor: '#f5f5f5' }}
            />

            <div className="terms-reg" style={{ marginBottom: 40 }}>

              <input
                type="checkbox"
                id="myCheckbox"
                name="myCheckbox"
                checked={isChecked}
                style={{ display: 'none' }}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="myCheckbox"
                style={{
                  position: 'relative',
                  cursor: 'pointer',
                  padding: '5px',
                  borderRadius: '3px',
                  marginRight: '5px',
                  width: '16px',
                  height: '16px',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #3FB871',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: isChecked ? 'block' : 'none',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#000000"
                    className="bi bi-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 7l5 5L13 0l2 2-10 10L3 9z" />
                  </svg>
                </span>
              </label>

              <span className="terms-font">
                By proceeding, you agree to Farmsell
                <a
                  href="/terms"
                  style={{ color: 'green', textDecoration: 'underline' }}
                  target="_blank" rel="noopener noreferrer"
                >
                  {'  '} Terms
                </a>
                {'  '} and
                <a
                  href="/privacy"
                  style={{ color: 'green', textDecoration: 'underline' }}
                  target="_blank" rel="noopener noreferrer"
                >
                  {' '} Privacy
                </a>.
              </span>

            </div>

            {formError.phone &&
              <span className="custom-validation">{formError.phone}</span>}

            {joinBtn !== 'Join Now' &&
              <MoonLoader
                color={'green'}
                loading={true}
                cssOverride={override}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />}
            {joinBtn === 'Join Now' &&
              <button type="submit" onClick={() => verifyEmail()}>
                {joinBtn}
              </button>}
          </div>

        </div>}
      <div className="terms-reg-container">

        <div>
          <div
            className="super-header fixed-top"
            style={{
              backgroundColor: 'white',
              width: '100%',
              top: '0',
              left: '0',
              right: '0',
              boxShadow: '0 10px 6px rgba(0, 0, 0, 0.08)', // Elevation
              zIndex: '10', // Ensure it's above other elements
            }}
          >
            <Navbar
              expand="lg"
              style={{
                backgroundColor: 'white',
                boxShadow: '0 0px 4px rgba(0, 0, 0, 0.1)',
                paddingLeft: '10%',
                paddingRight: '10%',
                flexWrap: 'noWrap',
              }}
            >
              <Navbar.Brand
                onClick={() => redirectToHomePage()}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={HeaderImage}
                  alt="Home Icon"
                  className="home-icon img-fluid"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarNav" />
              <Navbar.Collapse id="navbarNav" className="justify-content-end">
                <Nav
                  className="mr-auto"
                  id="header"
                  style={{ marginLeft: '20%', marginRight: '0%' }}
                />
                <Button
                  className="btn btn-primary"
                  style={{
                    color: '#ffff',
                    border: 'none',
                    backgroundColor: '#3FB871',
                    borderRadius: '10px',

                    transition: 'background-color 0.3s',
                    cursor: 'pointer', // Add cursor pointer for better UX
                  }}
                  onMouseOver={e =>
                    (e.target.style.backgroundColor = '#F7B618')}
                  onMouseOut={e => (e.target.style.backgroundColor = '#3FB871')}
                  onClick={() => joinFarmsellRedirect()}
                >
                  Join Now, It's Free
                </Button>

              </Navbar.Collapse>
            </Navbar>
          </div>

        </div>{' '}
      </div>{' '}
    </div>
  );
};

export default Registration;
