import React, { useState, useEffect } from "react";
import "../../assets/styles/heroSection.css";
import HeroImage from "../../assets/images/Hero/hero3.png";
import GradientText from "./GradientText";

const HeroSection = () => {
  const originalText = "Connect with Farmers";
  const replacementTexts = [
    { text: "Hotels", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Supermarkets", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Agro-Input Enterprises", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Agro-Based Industries", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
    { text: "Smallholder Farmers", gradient: "linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113))" },
  ];
  const [modifiedText, setModifiedText] = useState(originalText);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomReplacement = replacementTexts[Math.floor(Math.random() * replacementTexts.length)];
      setModifiedText(randomReplacement);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [replacementTexts]);

  return (
    <div className="hero-section">
      <div className="hero-content">
        {/* Text Section */}
        <div className="hero-texts">
          <h1 className="hero-h">
          Transform Farming with <span className="hero-span">Farmsell.</span> Your Trusted{" "}
            <span className="hero-span">B2B Marketplace</span>
          </h1>
          <p className="hero-p">
            Connect with <GradientText text={modifiedText.text} gradient={modifiedText.gradient} /> to maximize your profitability
          </p>
          {/* <p className="hero-pdown">to maximize your profitability</p> */}
        </div>

        {/* Buttons */}
        <div className="hero-buttons">
          <button className="hero-button primary">Join Farmsell, It's Free</button>
          <button className="hero-button">Download the App</button>
        </div>

        {/* Image */}
        <div className="hero-image">
          <img className="hero-images" src={HeroImage} alt="Hero" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
