import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/ImagePopup/ImagePopup.css";
import mainImage from "../../assets/images/popimage.png";

const ImagePopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [userAction, setUserAction] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const popupAlreadyShown = sessionStorage.getItem("popupShown");
    const userActionDone = sessionStorage.getItem("userAction");

    if (!popupAlreadyShown && !userActionDone) {
      const timer = setTimeout(() => setShowPopup(true), 10000); 
      return () => clearTimeout(timer); 
    }
  }, []);

  // Handle close button or overlay click
  const closePopup = () => {
    setShowPopup(false);
    sessionStorage.setItem("popupShown", "true");
  };

  // Handle join button click
  const handleJoinClick = () => {
    setUserAction(true);
    setShowPopup(false);
    sessionStorage.setItem("userAction", "true");
    navigate("/join-farmsell"); // Redirect to /join-farmsell
  };

  // Detect exit intent if no action was taken
  useEffect(() => {
    const handleExitIntent = (e) => {
      const userActionDone = sessionStorage.getItem("userAction");
      if (!userActionDone && e.clientY < 0) {
        setShowPopup(true);
      }
    };

    document.addEventListener("mouseleave", handleExitIntent);
    return () => document.removeEventListener("mouseleave", handleExitIntent); 
  }, []);

  // Close popup on Escape key press
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closePopup();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown); 
  }, []);

  // Trap focus within the popup for accessibility
  useEffect(() => {
    if (!showPopup) return;

    const focusableElements = document.querySelectorAll(
      ".popup-container button, .popup-container a"
    );
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    const handleTabKey = (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabKey);
    return () => document.removeEventListener("keydown", handleTabKey); 
  }, [showPopup]);

  return showPopup ? (
    <div className="popup-overlay" onClick={(e) => e.target.className === "popup-overlay" && closePopup()}>
      <div className="popup-container">
        <button className="close-btn" onClick={closePopup}>&times;</button>
        <div className="popup-text">
          <h2 className="popup-title">Join Farmsell Today</h2>
          <p className="popup-para">Unleash the potential of your agricultural transactions!</p>
          <button className="cta-button" onClick={handleJoinClick}>Join Now, It’s Free</button>
          <p className="legal-popup">
            By continuing, you agree to Farmsell’s{" "}
            <a
              href="https://farmsell.org/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="popup-link"
            >
              Terms of Use
            </a>
            . Read our{" "}
            <a
              href="https://farmsell.org/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="popup-link"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <div className="popup-image">
          <img src={mainImage} alt="Farmsell Promotion" />
        </div>
      </div>
    </div>
  ) : null;
};

export default ImagePopup;
