import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OIDImage1 from '../../assets/images/ImageA.png';
import OIDImage3 from '../../assets/images/ImageC.png';
import OIDImage4 from '../../assets/images/imageD.png';
import OIDImage5 from '../../assets/images/imageE.png';
import OIDImage6 from '../../assets/images/imageF.png';
import OIDImage7 from '../../assets/images/imageG.png';
import OIDImage8 from '../../assets/images/imageH.png';
import OIDImage9 from '../../assets/images/imageI.png';
import OIDImage10 from '../../assets/images/imageJ.png';
import OIDImage11 from '../../assets/images/imageK.svg';
import '../../assets/styles/TrustedClient.css';

const TrustedClient = () => {
  const images = [
    { src: OIDImage1, alt: 'Image 1' },
    { src: OIDImage3, alt: 'Image 3' },
    { src: OIDImage4, alt: 'Image 4' },
    { src: OIDImage5, alt: 'Image 5' },
    { src: OIDImage6, alt: 'Image 6' },
    { src: OIDImage7, alt: 'Image 7' },
    { src: OIDImage8, alt: 'Image 8' },
    { src: OIDImage9, alt: 'Image 9' },
    { src: OIDImage10, alt: 'Image 10' },
    { src: OIDImage11, alt: 'Image 11' },
  ];

  const sliderSettings = {
    infinite: true,
    slidesToShow: 4, 
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0, 
    cssEase: 'linear',
    variableWidth: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: false, 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Adjust number of logos for tablets
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Adjust for smaller tablets
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Adjust for mobile screens
        },
      },
    ],
  };

  return (
    <section className="trusted-clients-section">
      <div className="section-header text-center">
        <h1 className="title">
          Trusted by over <span className="highlight">10,000</span> Clients
        </h1>
        <p className="subtitle">
          From Individuals to Groups and Businesses
        </p>
      </div>
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <div key={index} className="client-logo-wrapper">
            <div className="client-logo-card">
              <img
                src={image.src}
                alt={image.alt}
                className="client-logo"
              />
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default TrustedClient;
