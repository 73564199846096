// WhoWeAre.js

import React from 'react';
import '../../assets/styles/WhoWeAre.css';
import myImage1 from '../../assets/images/ourservice/Component 30.png';

const WhoWeAre = () => {
  return (
    <div className="container-WhoWeAre">
      <div className="imageContainer">
        <img src={myImage1} alt="Sample" className="sample1" style={{Width:'470px'}} />

      </div>
      <div className="textContainer">
        <h2 className='who'>Who are <span className='We'>We</span></h2>
        <p className='heartbeat'>
          Farmsell is the heartbeat of agricultural transformation, a digital ecosystem designed to empower farmers and redefine B2B transactions. Beyond being a marketplace, Farmsell leverages AI for seamless matchmaking, maintains an exclusive agricultural focus, and offers a user-friendly platform
        </p>
        <p className='heartbeat'>
          Connecting smallholders, enterprises, and industries, Farmsell stands as the pivotal force reshaping the landscape of agribusiness, fostering efficiency, reducing losses, and contributing to sustainable growth in the agricultural sector
        </p>
      </div>
    </div>
  );
};

export default WhoWeAre;
