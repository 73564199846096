import React from "react";
import '../../../assets/styles/about-us/ourachievments.css';
import Transction from '../../../assets/images/aboutus/Transaction.svg';
import Loyality from '../../../assets/images/aboutus/Customer Loyalty.svg';
import Security from '../../../assets/images/aboutus/Security.svg';
import Uptime from '../../../assets/images/aboutus/Up Time.svg';
import Users from '../../../assets/images/aboutus/Users.svg';
import Sold from '../../../assets/images/aboutus/sold.svg';

const OurAchivements = () => {
    return (
        <div className="ourachivements">
            <div className='header'>
                <h2>Our <span className='colored'>Achievements</span>, Countless  <span className='colored'>Successes</span></h2>

                <div className='desc-text'>
                    Join a community that thrives on success! Farmsell boasts impressive statistics - from successful transactions to satisfied customers. Our achievements quantify the impact we've made in transforming the agricultural marketplace
                </div>
            </div>
            <div className="our-container">
                <div className="item">
                    <div className="icon">
                        <img src={Transction} alt="" />
                    </div>
                    <div className="num">95%</div>
                    <div className="desc">Successful Transactions</div>
                </div>
                <div className="item">
                    <div className="icon">
                        <img src={Security} alt="" />
                    </div>
                    <div className="num">10K+</div>
                    <div className="desc">Downloads</div>
                </div>
                <div className="item">
                    <div className="icon">
                        <img src={Uptime} alt="" />
                    </div>
                    <div className="num">2.7K+</div>
                    <div className="desc">Active Users</div>
                </div>
                <div className="item">
                    <div className="icon">
                        <img src={Loyality} alt="" />
                    </div>
                    <div className="num">$100K+</div>
                    <div className="desc">Government Recognition Grant</div>
                </div>
                <div className="item">
                    <div className="icon">
                        <img src={Users} alt="" />
                    </div>
                    <div className="num">$112K+</div>
                    <div className="desc">Users Revenue Generation</div>
                </div>
                <div className="item">
                    <div className="icon">
                        <img src={Sold} alt="" />
                    </div>
                    <div className="num">450K+</div>
                    <div className="desc">Tonnes of Trade Volume</div>
                </div>
            </div>
        </div>
    )
}

export default OurAchivements;