import React from 'react';
import { Helmet } from 'react-helmet';

function MetaTags() {
    return (
        <div>
            <Helmet>
                <meta property="og:title" content="Farmsell Bringing Markets Closer" />
                <meta property="og:description" content="Transform Agricultural trade with Farmsell" />
                <meta property="og:image" content="../assets/images/header/Header.png" />
                <meta property="og:url" content="https://farmsell.org/" />
                <meta property="og:type" content="website" />
            </Helmet>
        </div>
    )
}

export default MetaTags