import axios from 'axios';

import constants from './constants';
//import HelperFunction from './helper';
//import storageData from '../component/Shared/getStorageData';

//var User = { user_id: 4 };

const Request = {
    async postMethod(action, object, Token) {
        const url = constants.url + action;

        let formData = new FormData();

        // append your data
        for (var key in object) {
            formData.append(key, object[key]);
        }
        //formData.append('zone', HelperFunction.getLocalTimeZone());

        const token = '';

        return axios.post(url, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },




    async getMethod(action) {
        const url = constants.url + action;

        const token = '';

        return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    },


};

export default Request;
